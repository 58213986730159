import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { SearchHeader } from 'Components'
import { FacetPanel } from 'Components'
import { SearchResult } from 'Components'
import { VehicleWidget } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { VehicleWidgetDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'

export const compMap = {
  SearchPage,
SearchHeader,
FacetPanel,
SearchResult,
VehicleWidget,
FacetDialog,
SearchBoxDialog,
VehicleWidgetDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'FacetDialog': TemplateFacetDialog,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog
};