//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-0:_2324,_3484,_12,_5748,_9476,_5276,_780,_1840;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-0')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-0', "_2324,_3484,_12,_5748,_9476,_5276,_780,_1840");
        }
      }catch (ex) {
        console.error(ex);
      }